/* .navbar {
  margin-top: 2rem !important;
  margin-bottom: -2rem !important;
} */

.nav-link {
  color: #222;
  font-size: 2rem;
  margin: 1rem;
  text-decoration: none;
}

.logo {
  width: 20rem;
}

.top-section {
  margin-top: 6rem;
  text-align: left;
}

.desktop-landing-text {
  color: #222;
  font-size: 3rem;
  line-height: 1.25;
  /* letter-spacing: -0.1rem; */
  font-weight: 300;
  /* text-align: center; */
}

.desktop-landing-text > span {
  font-weight: 400;
}

.mobile-landing-text {
  color: #222;
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: -0.1rem;
  font-weight: 400;
  /* text-align: center; */
}

.call-to-action {
  margin-top: 7vh;
  font-size: 3.6rem;
  letter-spacing: -0.1rem;
  font-weight: 300;
}

.landing-form {
  text-align: left;
  margin: 3rem 0 2rem 0;
}

.landing-form .field {
  display: inline;
  margin: 0.2rem;
}

.landing-form input,
.landing-form select,
.landing-form button {
  height: 5rem !important;
}

.section-three {
  text-align: center;
}

.video-container {
  position: relative;
  max-width: 100%;
  text-align: center;
  /* right: 0; */
}

.video {
  border-radius: 1rem;
  max-width: 85%;
  margin: 1rem auto 0;
}
