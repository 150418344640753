#outer {width:100%;height:0.5vh;background:#54bcc7; position: absolute;}
#col1, #col2, #col3, #col4, #col5 {
 float:left;
 width:16.6%;
 background:#66c3ae;
 height:0.5vh;
}
#col2{background: #35adfc;}
#col3{background: #eac366;}
#col4{background: #dc6c6c;}
#col5{background: #eb937c;}
.clearer {
 height:1px;
 overflow:hidden;
 clear:both;
 margin-top:-1px;
}

.heading {
    font-size: 6rem;
    font-weight: 600;
    text-align: left;
    margin: 2vw 0 2vw 0;
}

.heading-center {
  font-size: 6rem;
  font-weight: 600;
  text-align: center;
  margin: 2vw 0 2vw 0;
}

.visit-app {
  color: #20ADDA;
  cursor: pointer;
}

@keyframes textRollDown{
    0%{
      opacity: 1;
      transform: rotateX(90deg);
    }
    25%{
        opacity: 1;
        transform: rotateX(0deg);
      }
    50%{
      opacity: 1;
      transform: rotateX(0deg);
    }
    75%{
        opacity: 1;
        transform: rotateX(0deg);
      }

    100%{
      opacity: 1;
      transform: rotateX(90deg);
    }
  }
  
  #sequence{
      display: inline-block;
      animation-name: textRollDown;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      font-weight: 500;
  }

  select,
  select option {
    color: #000000;
  }
  
  select:invalid,
  select option[value=""] {
    color: #555;
  }
  
  label {
    display: block;
    margin: 16px 0;
  }
  
  /*Added for browser compatibility*/
  [hidden] {
    display: none;
  }