.directions-area {
  margin-top: 2rem;
}

.display-step > img {
  max-width: 30rem;
  max-height: 30rem;
}

.instruction-text {
  font-size: 2rem;
}

.footer-text {
  text-align: center;
  font-weight: 600;
  margin: 10rem 0 2rem 0;
}

/* or whatever mobile viewport */
@media only screen and (max-width: 960px) {
  .filler { display: none}
}
