.dropzoneContainer {

}

.dropzone {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 120px;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all .15s linear;
  border-radius: 4px; */
}

.previewImage {
  /* width: auto;
  max-height: 30rem;
  max-width: 30rem;
  border-radius: 4px;
  display: inline; */
  display: block;
  width: 35rem;
  border-radius: 4px;
}

.previewContainer {
  /* padding: 40px 3%;
  min-height: 60px;
  z-index: 1;
  display: inline; */
  position: relative;
  width: 35rem;
  margin: auto;
}

.inputLabel {
  padding: 8rem;
  text-align: center;
  border: 4px dashed #ccc;
  border-radius: 4px;
  margin: auto;
  width: 15rem;
  height: 5rem;
}

.inputLabelWithFiles {
  padding: 8rem;
  text-align: center;
  border: 4px dashed #ccc;
  border-radius: 4px;
  margin: auto;
  width: 15rem;
  height: 5rem;
}

.instructions {
  width: 100%;
  margin-top: 1rem;
}

.submitButton {
  display: block;
  margin: 2rem auto;
  width: 100%;
}

.fileUpload {
  visibility: hidden;
}

.r {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.progress {
  width: 100%;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.background-loading {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
}

.upload-footer {
  margin-top: 2rem;
  text-align: center;
}

.loading {
  width: 25rem;
  height: 25rem;
  background: white;
  z-index: 1;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10rem;
  border-radius: 2rem;
}

.loading > p {
  text-align: center;
  margin-top: 5rem;
  font-size: 1.8rem;
  font-weight: 600;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
/* .spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: #07d;
  animation: spinner .6s linear infinite;
} */
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 10rem;
  left: 30%;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: #07d;
  animation: spinner .6s linear infinite;
}
