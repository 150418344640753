.unique-link {
    color: #35adfc;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 3rem;
}

.confirmation-text {
    font-size: 1.9rem;
    text-align: center;
}